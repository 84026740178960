@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-size: 16px;
  }
  @font-face {
    font-family: "Neue Montreal";
    src: url("../fonts/NeueMontreal-Light.otf") format("opentype");
    font-weight: 300;
  }
  @font-face {
    font-family: "Neue Montreal";
    src: url("../fonts/NeueMontreal-LightItalic.otf") format("opentype");
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: "Neue Montreal";
    src: url("../fonts/NeueMontreal-Book.otf") format("opentype");
    font-weight: 400;
  }
  @font-face {
    font-family: "Neue Montreal";
    src: url("../fonts/NeueMontreal-Regular.otf") format("opentype");
    font-weight: 450;
  }
  @font-face {
    font-family: "Neue Montreal";
    src: url("../fonts/NeueMontreal-Italic.otf") format("opentype");
    font-weight: 450;
    font-style: italic;
  }
  @font-face {
    font-family: "Neue Montreal";
    src: url("../fonts/NeueMontreal-Medium.otf") format("opentype");
    font-weight: 530;
  }
  @font-face {
    font-family: "Neue Montreal";
    src: url("../fonts/NeueMontreal-MediumItalic.otf") format("opentype");
    font-weight: 530;
    font-style: italic;
  }
  @font-face {
    font-family: "Neue Montreal";
    src: url("../fonts/NeueMontreal-Bold.otf") format("opentype");
    font-weight: 800;
  }
  @font-face {
    font-family: "Neue Montreal";
    src: url("../fonts/NeueMontreal-BoldItalic.otf") format("opentype");
    font-weight: 800;
    font-style: italic;
  }
  
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Toggle A */
input:checked ~ .dot {
  transform: translateX(100%);
}

/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
}

/* Toggle B */
.dotbg {
  background-color: rgb(154, 148, 227);
}

.shimmer {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmerKeys;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 1200px 100%;
  color: transparent;
}

@keyframes shimmerKeys {
  0% {
      background-position: -1200px 0;
  }
  100% {
      background-position: 1200px 0;
  }
}

@keyframes slowspin {
  to {
    transform: rotate(360deg);
  }
}
.animate-spin-slow {
  animation: spin 3s linear infinite;
}
